import useScript from '../hooks/useScript';

const SimpleAnalytics = () => {

    useScript('https://scripts.simpleanalyticscdn.com/latest.js', { async: 'true', defer: 'true' });

    return;

}

export default SimpleAnalytics;