import { useEffect } from 'react';

const useScript = (src, options = {}) => {

    useEffect(() => {

        const scriptExists = document.querySelector(`script[src="${src}"]`);

        if (scriptExists) {
            return;
        }

        const script = document.createElement('script');

        script.src = src;

        Object.keys(options).forEach(key => {
            script.setAttribute(key, options[key]);
        });

        document.head.appendChild(script);

        return () => {
            document.head.removeChild(script);
        }

    }, [src]);

};

export default useScript;