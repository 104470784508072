import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { useAuth } from '../auth';
import useScript from '../hooks/useScript';
import billingService from '../services/billingService';
import StripePaymentButton from './StripePaymentButton';

const Paywall = ({ open, onClose }) => {

    const auth = useAuth();
    const [products, setProducts] = useState();

    const theme = useTheme();

    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const md = useMediaQuery(theme.breakpoints.up('md'));

    useScript('https://js.stripe.com/v3/buy-button.js', { async: true });

    useEffect(() => {

        const loadProducts = async () => {

            const result = await billingService.listProducts();
            setProducts(result);

        }

        loadProducts();

    }, []);

    if (!products) {
        return;
    }

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullScreen={fullScreen}
            maxWidth={md ? 'md' : 'sm'}
        >
            <IconButton
                aria-label="close"
                onClick={onClose}
                sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                }}
            >
                <CloseIcon />
            </IconButton>
            <DialogContent sx={{
                display: 'flex',
                flexDirection: { xs: 'column', md: 'row' },
                p: 6,
                mt: 1,
                gap: 4
            }}>
                {products.products.map((product, idx) =>
                    <Card
                        key={idx}
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            flexBasis: 0,
                            flexGrow: 1,
                            p: 2,
                            overflow: { xs: 'visible', md: 'hidden' }
                        }}
                    >
                        <CardContent>
                            <Typography variant="h5" align="center" fontWeight="bold" sx={{ mb: 6 }}>
                                {product.title}
                            </Typography>
                            {product.description &&
                                <Typography variant="h6" sx={{ mb: 4 }}>
                                    {product.description}
                                </Typography>
                            }
                            <Stack spacing={1}>
                                {product.features.map((feature, idx) =>
                                    <Box
                                        key={idx}
                                        sx={{
                                            display: 'flex',
                                            columnGap: 1
                                        }}
                                    >
                                        <CheckIcon sx={{ color: 'green', height: '2rem' }} />
                                        <Typography variant="h6">
                                            {feature}
                                        </Typography>
                                    </Box>
                                )}
                            </Stack>
                        </CardContent>
                        <CardActions
                            sx={{
                                height: '220px',
                                justifyContent: 'center'
                            }}>
                            <StripePaymentButton
                                buyButtonId={product.buyButtonId}
                                publishableKey={products.stripePublishableKey}
                                clientReferenceId={auth.user.userId}
                                customerEmail={auth.user.email}
                            />
                        </CardActions>
                    </Card>
                )}
            </DialogContent>
        </Dialog>
    );

}

Paywall.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func
};


export default Paywall;