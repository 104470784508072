import useScript from '../hooks/useScript';

const PlausibleAnalytics = () => {

    useScript('https://plausible.io/js/script.js', { defer: 'true', 'data-domain': 'swe-resume.com' });

    return;

}

export default PlausibleAnalytics;